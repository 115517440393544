import React from "react"
import {withPrefix} from "gatsby"
// import { Helmet } from "react-helmet"
// import { Link } from "gatsby"

import "../../styles/core.css"
import "../../styles/styles.css"
import Layout from "../../components/Layout"
import MenuOne from "../../components/MenuOne"
import MenuTwo from "../../components/MenuTwo"
import Content from "../../components/Content"
import Gallery from "../../components/Gallery"

const galImgs = ['/page-images/braniel-3572.jpg', '/page-images/lawrence_perspective_for_cover.jpg']

export default function PlanningApprovals() {
  return (
    <Layout breadcrumbs={["Initial Designs"]}>
      <Gallery items={
      galImgs.map(image => ({
          original: withPrefix(image),
          thumbnail: withPrefix(image),
        })
      )}
    />

      <Content>
        <p><strong>Initial Design</strong></p>
        <p>	The design process begins by collecting all information from you regarding your wishes, needs and expectations of the finished project.  This forms the client brief and coupled with information we gather from site visits and surveys allows us to make initial sketches and begin the design process.</p>
        <p>	Through a series of client review meetings we revise and embellish the sketch designs until a final agreed scheme design is achieved.</p>
      </Content>

      <div class="region region-content-bottom">
        <MenuOne active="what-we-do" />
        <MenuTwo active="initial-designs" items={[
          "../initial-designs",
          "../planning-approvals",
          "../building-control",
          "../site-operations",
          "../other-services"
        ]} />
      </div>
  </Layout>

  )
}
